.transparent-card {
  background-color: transparent;
  min-width: 440px;
}

.flex-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}

.responsive-image {
  flex: 0 0 auto;
  width: 15%;
  border-radius: 5%;
  margin-right: 16px; // 기본 여백
}

.text-container {
  padding: 0 16px 0 64px;
  align-content: center;// 모바일에서 패딩 조정
}

@media (max-width: 768px) {
  .flex-container {
    flex-direction: column; // 모바일에서 세로 정렬
    align-items: center; // 가운데 정렬
    text-align: center; // 텍스트 가운데 정렬
  }

  .flex-container-reverse {
    flex-direction: column-reverse; // 모바일에서 세로 정렬
    align-items: center; // 가운데 정렬
    text-align: center; // 텍스트 가운데 정렬
  }

  .responsive-image {
    width: 80%; // 모바일에서 이미지 크기 조정
    border-radius: 5%;
    margin: 0 0 16px; // 아래쪽에 여백 추가
  }

  .text-container {
    padding: 0px 32px;
  }
}

.section {
  width: 100%;
  height: 1080px; // 기본 높이 설정 (예: 데스크톱)

  @media (max-width: 768px) { // 모바일 조건 (해당 크기를 필요에 맞게 조정)
    height: 640px;
  }

  align-content: center;
}

.title {
  text-align: center;
  padding: 20px;
  color: #ffffff;
  height: 100%;
  align-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("~/public/images/title.png");

  @media (max-width: 768px) {
    padding: 30px;
  }
}

.solution-title {
  padding: 20px 0 20px 0;

  .h1 {
    font-weight: bold;
    font-size: 44px;
    color: white;
  }
  
  @media screen {
    padding: 0 30px 0 30px;
  }
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  width: 16% !important;
}

