.solution-page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 4rem;
  max-width: fit-content;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
}

.text-contents {
  width: fit-content;
  text-align: left;
}

.solution-description {
  font-size: 18px;
}

.main-image {
  width: 80%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  border-radius: 15px;

  @media (max-width: 768px) {
    margin: 30px 0 10px 0;
  }
}

.use-cases-container {
  text-align: center;
  padding: 4rem;
}

.use-cases-description {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 2rem;
  justify-content: center;
}

.use-cases-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.use-case {
  width: 300px;
  text-align: center;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.use-case-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.use-case h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.use-case p {
  font-size: 1rem;
}

.faq-container {
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: initial;

  max-width: fit-content;
  margin: auto;

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}

.faq-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  justify-content: left;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  p {
    font-size: 1rem;
    text-align: left;
  }
}

