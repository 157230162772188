.footer-container {
  padding: 40px 20px;
  background-color: #121212;
  width: 100vw;
  color: #fff;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-column {
  margin: 10px;
  text-align: start;
}

.footer-heading {
  margin-bottom: 15px;
  font-weight: bold;
}

.footer-text {
  margin: 5px 0;
}

.footer-social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;

  .footer-social-link {
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.2); // 아이콘에 호버 시 크기 조정 효과
    }
  }
}
